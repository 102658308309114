<div class="container welcome-wrapper">
  <div class="laptop-wrapper">
    <div class="laptop">
      <div class="content">
        <img [src]="'assets/welcome/' + ((uiFacade.isDarkTheme$ | async) ? 'dark-origin.webp' : 'light-origin.webp')" />
      </div>
      <div class="btm"></div>
      <div class="shadow"></div>
    </div>
  </div>

  <div class="info-block">
    <div class="info">
      <h1 class="title">
        <p>
          {{ 'welcome.startYour' | translate }}
          <span class="text-primary">{{ 'welcome.free' | translate }}</span>
          {{ 'welcome.trialToday' | translate }}
        </p>
        <p>
          <a href="#" class="user-info" (click)="login()">
            {{ 'welcome.clickHere' | translate }}
          </a>
          {{ 'welcome.toSignUp' | translate }}
        </p>
      </h1>

      <p class="access">{{ 'welcome.getUnlimitedAccess' | translate }}</p>
      <p class="welcome-email">
        {{ 'welcome.emailUsAtStart' | translate }}
        <a href="mailto:info@proximilar.com">info&#64;proximilar.com</a>
        {{ 'welcome.emailUsAtEnd' | translate }}
      </p>
    </div>

    <!-- <form class="form" [formGroup]="form" (ngSubmit)="submit()">
          <div class="form-group">
            <mat-form-field>
              <mat-label>{{ 'common.firstName' | translate }}</mat-label>
              <input matInput [placeholder]="'common.firstName' | translate" formControlName="firstName">
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ 'common.lastName' | translate }}</mat-label>
              <input matInput [placeholder]="'common.lastName' | translate" formControlName="lastName">
            </mat-form-field>
          </div>

          <div class="form-group">
            <mat-form-field>
              <mat-label>{{ 'common.email' | translate }}</mat-label>
              <input matInput [placeholder]="'common.email' | translate" formControlName="email">
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ 'common.company' | translate }}</mat-label>
              <input matInput [placeholder]="'common.company' | translate" formControlName="company">
            </mat-form-field>
          </div>

          <mat-form-field>
            <mat-label>{{ 'common.message' | translate }}</mat-label>
            <textarea matInput [placeholder]="'common.message' | translate" formControlName="message"></textarea>
          </mat-form-field>

          <button mat-raised-button color="primary" [disabled]="!form.valid || inRequest">{{ 'common.send' | translate }}</button>
        </form> -->
  </div>
</div>
